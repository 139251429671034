import React, { useState } from 'react'
import { Row, Col, Card, CardBody, CardTitle } from 'reactstrap'
import { BuildingSearch } from '@digitalworkflow/dwreactcommon'
import Configurations from './Configurations'

const BuildingSearchPage = () => {
  const [showFilterByPostalCode, setShowFilterByPostalCode] = useState<boolean>(true)
  const [showFilterByFMZ, setshowFilterByFMZ] = useState<boolean>(false)
  const [showFilterByRegion, setshowFilterByRegion] = useState<boolean>(false)
  const [showNearest, setShowNearest] = useState<boolean>(false)
  const [showDistance, setShowDistance] = useState<boolean>(true)
  const [clientIds, setClientIds] = useState<string>('')
  const [otherFilters, setOtherFilters] = useState<string>('')
  const [showFav, setShowFav] = useState<boolean>(false)
  const [minSelect, setMinSelect] = useState<number>(1)
  const [maxSelect, setMaxSelect] = useState<number>(10000)
  const [allowPaste, setAllowPaste] = useState<boolean>(true)
  const [templateLine1, setTemplateLine1] = useState<string>('%ID% | %BUILDING_NAME%')
  const [customFilter, setCustomFilter] = useState('LOCATION_CLASS!=LAND')
  const [templateLine2, setTemplateLine2] = useState<string>('%ADDRESS%')
  const [values, setValues] = useState<string[]>([
    'INO02001',
    'INO02004',
    'INO02005',
    'INO02007',
    'INO02008',
    'INO02009',
    'INO02010',
    'INO02011',
    'INO02012',
    'INO02013',
    'INO02014',
    'INO02015',
    'INO02016',
    'INO02017',
    'INO02018',
    'INO02019',
    'INO02020',
    'INO02021',
    'INO02022',
    'INO02023',
    'INO02024',
    'INO02025',
    'INO02026',
    'INO02027',
    'INO02028',
    'INO02029',
    'INO02030',
    'INO02031',
    'INO02032',
    'INO02033',
    'INO02034',
    'INO02035',
    'INO02036',
    'INO02037',
    'INO02038',
    'INO02039',
    'INO02040',
    'INO02041',
    'INO02042',
    'INO02043',
    'INO02044',
    'INO02045',
    'INO02046',
    'INO02047',
    'INO02048',
    'INO02049',
    'INO02050',
    'INO02051',
    'INO02052',
    'INO02055',
    'INO02056',
    'INO02057',
    'INO02058',
    'INO02059',
    'INO02060',
    'INO02061',
    'INO02062',
    'INO02063',
    'INO02064',
    'INO02065',
    'INO02066',
    'INO02067',
    'INO02068',
    'INO02069',
    'INO02070',
    'INO02071',
    'INO02072',
    'INO02074',
    'INO02075',
    'INO02076',
    'INO02077',
    'INO02078',
    'INO02079',
    'INO02080',
    'INO02083',
    'INO02084',
    'INO02085',
    'INO02086',
    'INO02087',
    'INO02088',
    'INO02089',
    'INO02090',
    'INO02091',
    'INO02092',
    'INO02093',
    'INO02094',
    'INO02095',
    'INO02096',
    'INO02097',
    'INO02098',
    'INO02099',
    'INO02100',
    'INO02101',
    'INO02102',
    'INO02103',
    'INO02104',
    'INO02105',
    'INO02106',
    'INO02107',
    'INO02108',
    'INO02109',
    'INO02110',
    'INO02111',
    'INO02112',
    'INO02113',
    'INO02114',
    'INO02115',
    'INO02116',
    'INO02117',
    'INO02118',
    'INO02119',
    'INO02120',
    'INO02122',
    'INO02123',
    'INO02129',
    'INO02130',
    'INO02131',
    'INO02132',
    'INO02133',
    'INO02134',
    'INO02135',
    'INO02136',
    'INO02137',
    'INO02138',
    'INO02139',
    'INO02140',
    'INO02141',
    'INO02148',
    'INO02150',
    'INO02152',
    'INO02153',
    'INO02154',
    'INO02155',
    'INO02156',
    'INO02159',
    'INO02163',
    'INO02165',
    'INO02166',
    'INO02167',
    'INO02168',
    'INO02169',
    'INO02170',
    'INO02171',
    'INO02173',
    'INO02175',
    'INO02177',
    'INO02181',
    'INO02182',
    'INO02183',
    'INO02184',
    'INO02187',
    'INO02192',
    'INO02193',
    'INO02194',
    'INO02195',
    'INO02196',
    'INO02197',
    'INO02198',
    'INO02199',
    'INO02200',
    'INO02201',
    'INO02202',
    'INO02203',
    'INO02204',
    'INO02205',
    'INO02206',
    'INO02207',
    'INO02208',
    'INO02209',
    'INO02210',
    'INO02211',
    'INO02212',
    'INO02213',
    'INO02214',
    'INO02215',
    'INO02216',
    'INO02219',
    'INO02220',
    'INO02221',
    'INO02222',
    'INO02223',
    'INO02224',
    'INO02225',
    'INO02226',
    'INO02227',
    'INO02228',
    'INO02229',
    'INO02230',
    'INO02231',
    'INO02232',
    'INO02233',
    'INO02234',
    'INO02235',
    'INO02242',
    'INO02243',
    'INO02244',
    'INO02245',
    'INO02246',
    'INO02247',
    'INO02248',
    'INO02249',
    'INO02251',
    'INO02252',
    'INO02253',
    'INO02254',
    'INO02255',
    'INO02256',
    'INO02257',
    'INO02258',
    'INO02259',
    'INO02260',
    'INO02261',
    'INO02262',
    'INO02263',
    'INO02264',
    'INO02265',
    'INO02266',
    'INO02267',
    'INO02268',
    'INO02269',
    'INO02270',
    'INO02271',
    'INO02272',
    'INO02273',
    'INO02274',
    'INO02275',
    'INO02276',
    'INO02277',
    'INO02281',
    'INO02282',
    'INO02283',
    'INO02284',
    'INO02285',
    'INO02286',
    'INO02287',
    'INO02288',
    'INO02289',
    'INO02290',
    'INO02293',
    'INO02295',
    'INO02312',
    'INO02327',
    'INO02328',
    'INO02329',
    'INO02330',
    'INO02334',
    'INO02335',
    'INO02336',
    'INO02337',
    'INO02338',
    'INO02339',
    'INO02340',
    'INO02341',
    'INO02342',
    'INO02343',
    'INO02344',
    'INO02345',
    'INO02346',
    'INO02347',
    'INO02348',
    'INO02349',
    'INO02350',
    'INO02351',
    'INO02352',
    'INO02353',
    'INO02354',
    'INO02367',
    'INO02370',
    'INO02371',
    'INO02372',
    'INO02373',
    'INO02374',
    'INO02375',
    'INO02376',
    'INO02378',
    'INO02380',
    'INO02385',
    'INO02386',
    'INO02388',
    'INO02390',
    'INO02391',
    'INO02392',
    'INO02395',
    'INO02398',
    'INO02399',
    'INO02400',
    'INO02401',
    'INO02403',
    'INO02404',
    'INO02405',
    'INO02406',
    'INO02407',
    'INO02408',
    'INO02409',
    'INO02410',
    'INO02411',
    'INO02419',
    'INO02420',
    'INO02422',
    'INO02423',
    'INO02424',
    'INO02427',
    'INO02428',
    'INO02429',
    'INO02430',
    'INO02431',
    'INO02432',
    'INO02433',
    'INO02436',
    'INO02437',
    'INO02438',
    'INO02439',
    'INO02440',
    'INO02441',
    'INO02442',
    'INO02443',
    'INO02444',
    'INO02445',
    'INO02446',
    'INO02447',
    'INO02448',
    'INO02478',
    'INO02479',
    'INO02481',
    'INO02482',
    'INO02483',
    'INO02484',
    'INO02485',
    'INO02486',
    'INO02487',
    'INO02499',
    'INO02500',
    'INO03501',
    'INO03502',
    'INO03503',
    'INO03504',
    'INO03505',
    'INO03506',
    'INO03507',
    'INO03508',
    'INO03509',
    'INO03510',
    'INO03511',
    'INO03512',
    'INO03513',
    'INO03514',
    'INO03515',
    'INO03516',
    'INO03518',
    'INO03519',
    'INO03520',
    'INO03521',
    'INO03522',
    'INO03523',
    'INO03525',
    'INO03526',
    'INO03527',
    'INO03528',
    'INO03529',
    'INO03531',
    'INO03532',
    'INO03533',
    'INO03534',
    'INO03535',
    'INO03536',
    'INO03537',
    'INO03538',
    'INO03539',
    'INO03540',
    'INO03541',
    'INO03542',
    'INO03544',
    'INO03546',
    'INO03547',
    'INO03550',
    'INO03552',
    'INO03553',
    'INO03555',
    'INO03556',
    'INO03557',
    'INO03558',
    'INO03559',
    'INO03561',
    'INO03562',
    'INO03563',
    'INO03564',
    'INO03565',
    'INO03566',
    'INO03567',
    'INO03568',
    'INO03569',
    'INO03570',
    'INO03571',
    'INO03572',
    'INO03573',
    'INO03574',
    'INO03577',
    'INO03578',
    'INO03579',
    'INO03580',
    'INO03581',
    'INO03582',
    'INO03583',
    'INO03584',
    'INO03585',
    'INO03586',
    'INO03587',
    'INO03588',
    'INO03589',
    'INO03590',
    'INO03591',
    'INO03592',
    'INO03593',
    'INO03594',
    'INO03595',
    'INO03596',
    'INO03597',
    'INO03598',
    'INO03599',
    'INO03600',
    'INO03601',
    'INO03602',
    'INO03603',
    'INO03604',
    'INO03605',
    'INO03606',
    'INO03607',
    'INO03608',
    'INO03611',
    'INO03612',
    'INO03614',
    'INO03615',
    'INO03617',
    'INO03618',
    'INO03619',
    'INO03620',
    'INO03621',
    'INO03622',
    'INO03623',
    'INO03624',
    'INO03625',
    'INO03626',
    'INO03627',
    'INO03628',
    'INO03629',
    'INO03630',
    'INO03631',
    'INO03632',
    'INO03633',
    'INO03634',
    'INO03635',
    'INO03638',
    'INO03639',
    'INO03640',
    'INO03641',
    'INO03643',
    'INO03646',
    'INO03647',
    'INO03649',
    'INO03650',
    'INO03651',
    'INO03652',
    'INO03653',
    'INO03658',
    'INO03660',
    'INO03661',
    'INO03663',
    'INO03664',
    'INO03665',
    'INO03666',
    'INO03667',
    'INO03668',
    'INO03669',
    'INO03670',
    'INO03671',
    'INO03672',
    'INO03673',
    'INO03674',
    'INO03675',
    'INO03676',
    'INO03677',
    'INO03678',
    'INO03679',
    'INO03680',
    'INO03681',
    'INO03682',
    'INO03683',
    'INO03684',
    'INO03685',
    'INO03688',
    'INO03689',
    'INO03690',
    'INO03692',
    'INO03693',
    'INO03695',
    'INO03696',
    'INO03697',
    'INO03698',
    'INO03699',
    'INO03700',
    'INO03701',
    'INO03702',
    'INO03706',
    'INO03707',
    'INO03708',
    'INO03709',
    'INO03710',
    'INO03711',
    'INO03712',
    'INO03713',
    'INO03714',
    'INO03720',
    'INO03721',
    'INO03722',
    'INO03730',
    'INO03731',
    'INO03732',
    'INO03734',
    'INO03735',
    'INO03736',
    'INO03737',
    'INO03739',
    'INO03740',
    'INO03741',
    'INO03742',
    'INO03744',
    'INO03745',
    'INO03752',
    'INO03753',
    'INO03754',
    'INO03755',
    'INO03756',
    'INO03757',
    'INO03758',
    'INO03759',
    'INO03760',
    'INO03761',
    'INO03762',
    'INO03763',
    'INO03764',
    'INO03791',
    'INO03792',
    'INO03793',
    'INO03794',
    'INO03795',
    'INO03796',
    'INO03797',
    'INO03798',
    'INO03799',
    'INO03802',
    'INO03803',
    'INO03805',
    'INO03806',
    'INO03807',
    'INO03809',
    'INO03817',
    'INO03818',
    'INO03828',
    'INO03829',
    'INO03830',
    'INO03831',
    'INO03832',
    'INO03833',
    'INO03834',
    'INO03835',
    'INO03836',
    'INO03837',
    'INO03838',
    'INO03839',
    'INO03840',
    'INO03841',
    'INO03844',
    'INO03845',
    'INO03848',
    'INO03849',
    'INO03850',
    'INO03851',
    'INO03852',
    'INO03853',
    'INO03854',
    'INO03855',
    'INO03856',
    'INO03857',
    'INO03858',
    'INO03859',
    'INO03861',
    'INO03865',
    'INO03885',
    'INO03886',
    'INO03887',
    'INO03888',
    'INO03889',
    'INO03890',
    'INO03891',
    'INO03892',
    'INO03893',
    'INO03898',
    'INO03899',
    'INO03900',
    'INO03901',
    'INO03902',
    'INO03903',
    'INO03904',
    'INO03905',
    'INO03906',
    'INO03907',
    'INO03908',
    'INO03909',
    'INO03910',
    'INO03911',
    'INO03912',
    'INO03913',
    'INO03914',
    'INO03915',
    'INO03916',
    'INO03917',
    'INO03918',
    'INO03919',
    'INO03920',
    'INO03921',
    'INO03922',
    'INO03923',
    'INO03924',
    'INO03925',
    'INO03926',
    'INO03927',
    'INO03928',
    'INO03929',
    'INO03930',
    'INO03931',
    'INO03932',
    'INO03933',
    'INO03934',
    'INO03935',
    'INO03936',
    'INO03937',
    'INO03938',
    'INO03939',
    'INO03940',
    'INO03941',
    'INO03942',
    'INO03943',
    'INO03944',
    'INO03945',
    'INO03946',
    'INO03947',
    'INO03948',
    'INO03949',
    'INO03950',
    'INO03951',
    'INO03952',
    'INO03953',
    'INO03954',
    'INO03961',
    'INO03962',
    'INO03963',
    'INO03964',
    'INO03965',
    'INO03966',
    'INO03969',
    'INO03971',
    'INO03972',
    'INO03974',
    'INO03976',
    'INO03977',
    'INO03978',
    'INO03979',
    'INO03980',
    'INO03981',
    'INO03983',
    'INO03984',
    'INO03985',
    'INO03986',
    'INO03988',
    'INO03989',
    'INO03990',
    'INO03991',
    'INO03992',
    'INO03993',
    'INO03994',
    'INO03995',
    'INO03996',
    'INO03997',
    'INO03998',
    'INO01501',
    'INO01502',
    'INO01503',
    'INO01504',
    'INO01505',
    'INO01506',
    'INO01507',
    'INO01508',
    'INO01509',
    'INO01510',
    'INO01515',
    'INO01517',
    'INO01518',
    'INO01524',
    'INO01525',
    'INO01527',
    'INO01528',
    'INO01529',
    'INO01530',
    'INO01532',
    'INO01533',
    'INO01534',
    'INO01535',
    'INO01537',
    'INO01540',
    'INO01541',
    'INO01542',
    'INO01543',
    'INO01544',
    'INO01545',
    'INO01546',
    'INO01547',
    'INO01548',
    'INO01549',
    'INO01550',
    'INO01551',
    'INO01552',
    'INO01553',
    'INO01554',
    'INO01555',
    'INO01556',
    'INO01559',
    'INO01561',
    'INO01562',
    'INO01563',
    'INO01564',
    'INO01565',
    'INO01567',
    'INO01568',
    'INO01569',
    'INO01570',
    'INO01571',
    'INO01572',
    'INO01573',
    'INO01574',
    'INO01575',
    'INO01576',
    'INO01577',
    'INO01581',
    'INO01582',
    'INO01583',
    'INO01584',
    'INO01587',
    'INO01588',
    'INO01589',
    'INO01590',
    'INO01591',
    'INO01595',
    'INO01596',
    'INO01597',
    'INO01606',
    'INO01607',
    'INO01608',
    'INO01611',
    'INO01614',
    'INO01615',
    'INO01616',
    'INO01617',
    'INO01618',
    'INO01619',
    'INO01620',
    'INO01621',
    'INO01622',
    'INO01623',
    'INO01624',
    'INO01625',
    'INO01626',
    'INO01627',
    'INO01628',
    'INO01629',
    'INO01630',
    'INO01631',
    'INO01632',
    'INO01633',
    'INO01634',
    'INO01635',
    'INO01636',
    'INO01637',
    'INO01638',
    'INO01639',
    'INO01640',
    'INO01641',
    'INO01642',
    'INO01643',
    'INO01644',
    'INO01645',
    'INO01646',
    'INO01647',
    'INO01648',
    'INO01649',
    'INO01650',
    'INO01651',
    'INO01652',
    'INO01654',
    'INO01655',
    'INO01656',
    'INO01657',
    'INO01658',
    'INO01659',
    'INO01660',
    'INO01661',
    'INO01662',
    'INO01663',
    'INO01664',
    'INO01665',
    'INO01666',
    'INO01667',
    'INO01668',
    'INO01669',
    'INO01670',
    'INO01671',
    'INO01672',
    'INO01673',
    'INO01674',
    'INO01675',
    'INO01676',
    'INO01677',
    'INO01678',
    'INO01679',
    'INO01680',
    'INO01681',
    'INO01682',
    'INO01684',
    'INO01685',
    'INO01686',
    'INO01687',
    'INO01688',
    'INO01689',
    'INO01691',
    'INO01692',
    'INO01693',
    'INO01694',
    'INO01695',
    'INO01696',
    'INO01697',
    'INO01698',
    'INO01699',
    'INO01700',
    'INO01701',
    'INO01702',
    'INO01712',
    'INO01713',
    'INO01714',
    'INO01715',
    'INO01716',
    'INO01719',
    'INO01720',
    'INO01737',
    'INO01738',
    'INO01739',
    'INO01740',
    'INO01741',
    'INO01742',
    'INO01745',
    'INO01747',
    'INO01748',
    'INO01749',
    'INO01750',
    'INO01751',
    'INO01752',
    'INO01753',
    'INO01754',
    'INO01755',
    'INO01756',
    'INO01757',
    'INO01758',
    'INO01759',
    'INO01760',
    'INO01761',
    'INO01762',
    'INO01763',
    'INO01764',
    'INO01765',
    'INO01766',
    'INO01767',
    'INO01768',
    'INO01769',
    'INO01770',
    'INO01771',
    'INO01772',
    'INO01773',
    'INO01774',
    'INO01775',
    'INO01776',
    'INO01777',
    'INO01778',
    'INO01779',
    'INO01780',
    'INO01781',
    'INO01782',
    'INO01783',
    'INO01784',
    'INO01785',
    'INO01786',
    'INO01787',
    'INO01788',
    'INO01789',
    'INO01790',
    'INO01791',
    'INO01792',
    'INO01793',
    'INO01794',
    'INO01795',
    'INO01796',
    'INO01797',
    'INO01798',
    'INO01799',
    'INO01800',
    'INO01808',
    'INO01810',
    'INO01811',
    'INO01812',
    'INO01814',
    'INO01815',
    'INO01816',
    'INO01817',
    'INO01818',
    'INO01819',
    'INO01820',
    'INO01821',
    'INO01822',
    'INO01823',
    'INO01824',
    'INO01825',
    'INO01826',
    'INO01827',
    'INO01828',
    'INO01829',
    'INO01830',
    'INO01831',
    'INO01834',
    'INO01835',
    'INO01836',
    'INO01837',
    'INO01838',
    'INO01839',
    'INO01840',
    'INO01841',
    'INO01842',
    'INO01843',
    'INO01844',
    'INO01845',
    'INO01846',
    'INO01847',
    'INO01848',
    'INO01849',
    'INO01850',
    'INO01851',
    'INO01852',
    'INO01853',
    'INO01854',
    'INO01855',
    'INO01856',
    'INO01857',
    'INO01858',
    'INO01860',
    'INO01861',
    'INO01862',
    'INO01863',
    'INO01864',
    'INO01865',
    'INO01868',
    'INO01869',
    'INO01870',
    'INO01871',
    'INO01872',
    'INO01873',
    'INO01875',
    'INO01876',
    'INO01877',
    'INO01878',
    'INO01880',
    'INO01881',
    'INO01882',
    'INO01883',
    'INO01884',
    'INO01885',
    'INO01886',
    'INO01887',
    'INO01888',
    'INO01889',
    'INO01890',
    'INO01893',
    'INO01895',
    'INO01896',
    'INO01897',
    'INO01898',
    'INO01900',
    'INO01903',
    'INO01904',
    'INO01905',
    'INO01906',
    'INO01907',
    'INO01909',
    'INO01910',
    'INO01911'
  ])

  const onConfigChange = (type: string, val: any = null) => {
    switch (type) {
      case 'showFilterByPostalCode':
        setShowFilterByPostalCode(!showFilterByPostalCode)
        break
      case 'showFilterByFMZ':
        setshowFilterByFMZ(!showFilterByFMZ)
        break
      case 'showFilterByRegion':
        setshowFilterByRegion(!showFilterByRegion)
        break
      case 'showNearest':
        setShowNearest(!showNearest)
        break
      case 'showDistance':
        setShowDistance(!showDistance)
        break
      case 'clientIds':
        setClientIds(val)
        break
      case 'otherFilters':
        setOtherFilters(val)
        break
      case 'showFav':
        setShowFav(!showFav)
        break
      case 'minSelect':
        setMinSelect(val)
        break
      case 'maxSelect':
        setMaxSelect(val)
        break
      case 'allowPaste':
        setAllowPaste(!allowPaste)
        break
      case 'templateLine1':
        setTemplateLine1(val)
        break
      case 'templateLine2':
        setTemplateLine2(val)
        break
      case 'customFilter':
        setCustomFilter(val)
        break
      default:
        break
    }
  }
  const onConfigApply = () => {}
  const onConfigCancel = () => {}

  const handleChange = (items: string[]) => {
    console.log('Building Selected: ', items)
    setValues(items)
  }

  return (
    <Row>
      <Col lg={6}>
        <Card>
          <CardBody>
            <CardTitle>
              <h4>Building Search Control</h4>
            </CardTitle>
            <BuildingSearch
              showFilterPostcode={showFilterByPostalCode}
              showFMZID={showFilterByFMZ}
              showRegion={showFilterByRegion}
              otherFilters={otherFilters}
              showFav={showFav}
              showNearest={showNearest}
              minSelect={minSelect}
              maxSelect={maxSelect}
              allowPaste={allowPaste}
              showDistance={showDistance}
              templateLine1={templateLine1}
              templateLine2={templateLine2}
              clientIds={clientIds}
              onChange={handleChange}
              customFilter={customFilter}
              value={values}
              pageSize={100}
            />
          </CardBody>
        </Card>
      </Col>
      <Col lg={6}>
        <Card>
          <CardBody>
            <CardTitle>
              <h4>Search Tool Configurations</h4>
            </CardTitle>
            <Configurations
              showFilterByPostalCode={showFilterByPostalCode}
              showFilterByFMZ={showFilterByFMZ}
              showFilterByRegion={showFilterByRegion}
              showNearest={showNearest}
              showFav={showFav}
              showDistance={showDistance}
              minSelect={minSelect}
              maxSelect={maxSelect}
              allowPaste={allowPaste}
              clientIds={clientIds}
              templateLine1={templateLine1}
              templateLine2={templateLine2}
              onChange={onConfigChange}
              onApply={onConfigApply}
              onCancel={onConfigCancel}
              customFilter={customFilter}
            />
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default BuildingSearchPage
