import React, { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import { InputGroup } from 'reactstrap'
import moment from 'moment'

import { StringOptions } from '@digitalworkflow/dwtranslateclient'
import { FormFieldInputProps, useForm } from '../useForm'
import 'react-datepicker/dist/react-datepicker.css'

import './index.scss'

function getYear(d: Date) {
  return moment(d).year()
}

function getMonth(d: Date) {
  return moment(d).month()
}

function range(start: number, end: number) {
  return [...Array(end - start + 1).keys()].map((i) => i + start)
}

/**
 * DateInput component
 *
 * @component DateInput
 * @category FormElements
 */
const DateInput = ({
  step,
  field,
  is_on_modal,
  is_disabled,
  error,
  warning,
  disabledFields,
  editValues,
  handleChangeEditValues,
  handleSetEditValues
}: FormFieldInputProps) => {
  const {
    myFieldValue,
    setValue,
    isEditFieldOnModal,
    isFieldDisabled,
    handleChangeText,
    renderValidationError,
    getClassName,
    getPlacholder
  } = useForm({
    step,
    field,
    is_on_modal,
    is_disabled,
    error,
    warning,
    disabledFields,
    editValues,
    handleChangeEditValues,
    handleSetEditValues
  })

  const [isClearable, setIsClearable] = useState(false)
  const [minDate, setMinDate] = useState<Date>()
  const [maxDate, setMaxDate] = useState<Date>()
  const className = getClassName()

  /**
   * Used to set the value of a field.
   *
   * @param field - Points to the field.
   * @param date  - Used to set the value.
   * @returns Void
   */
  const handleChangeDate = (date: Date) => {
    const value = date ? moment(date).format('YYYY-MM-DD') : ''
    if (isEditFieldOnModal()) {
      handleChangeEditValues(field, value)
    } else {
      setValue(value)
    }
  }

  const years = range(1900, getYear(new Date()) + 1)
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]

  const fieldValue = isEditFieldOnModal() ? editValues[field.field] : myFieldValue

  useEffect(() => {
    const dateOptions = new StringOptions(field.option1)
    for (const key of dateOptions.Keys()) {
      // console.log('Debug Date Option:', key, '=', dateOptions.checkOption(key))
      if (key === 'isclearable') {
        setIsClearable(dateOptions.checkOption(key))
      }
      if (key === 'mindate') {
        setMinDate(new Date(dateOptions.checkOption(key)))
      }
      if (key === 'maxdate') {
        setMaxDate(new Date(dateOptions.checkOption(key)))
      }
    }
  }, [])

  let realPlaceHolder = getPlacholder()
  if (realPlaceHolder === '') {
    realPlaceHolder = 'yyyy-mm-dd'
  }

  return (
    <div className={isFieldDisabled() ? 'disabled' : ''}>
      <div className='d-inline-block date-input-field mr-2 mb-1'>
        <InputGroup className='custom-date-picker'>
          <div className='input-group mb-3 date-input-field'>
            <input
              className={className}
              type='text'
              name={isEditFieldOnModal() ? 'edit_' + field.field : field.field}
              value={fieldValue ? moment(fieldValue).format('YYYY-MM-DD') : ''}
              disabled={isFieldDisabled()}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const value = e.target.value
                const re = /^[0-9.\-/]*$/
                if (value !== '' && !re.test(value)) {
                  return
                }
                handleChangeText(e)
              }}
              data-testid={field.testid}
              placeholder={realPlaceHolder}
            />

            <div className={`input-group-append ${isFieldDisabled() ? 'disabled-datepicker' : ''}`}>
              <DatePicker
                portalId='datePicker'
                className='form-control'
                renderCustomHeader={({
                  date,
                  changeYear,
                  changeMonth,
                  decreaseMonth,
                  increaseMonth,
                  prevMonthButtonDisabled,
                  nextMonthButtonDisabled
                }) => (
                  <div
                    style={{
                      margin: 10,
                      display: 'flex',
                      justifyContent: 'center'
                    }}
                  >
                    <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                      {'<'}
                    </button>
                    <select value={getYear(date)} onChange={({ target: { value } }) => changeYear(parseInt(value))}>
                      {years.map((option: number) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>

                    <select
                      value={months[getMonth(date)]}
                      onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
                    >
                      {months.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>

                    <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                      {'>'}
                    </button>
                  </div>
                )}
                name={isEditFieldOnModal() ? 'edit_' + field.field : field.field}
                selected={moment(fieldValue).isValid() ? moment(fieldValue).toDate() : undefined}
                disabled={isFieldDisabled()}
                onChange={handleChangeDate}
                customInput={
                  <div className='input-group-button' role='button' data-toggle>
                    <i className='fa fa-calendar-alt bx' />
                  </div>
                }
                isClearable={isClearable}
                minDate={minDate}
                maxDate={maxDate}
              />
            </div>

            {renderValidationError()}
          </div>
        </InputGroup>
      </div>
    </div>
  )
}

export default DateInput
