import React, { useEffect, useState } from 'react'

import {
  AuditLogCollection,
  AuditLogDocumentType,
  FindTaskResults,
  LogicEngineDataDateTime,
  Task,
  TaskCollection
} from '@digitalworkflow/dwtranslateclient'
import { Modal } from 'reactstrap'

import './TaskHistory.scss'

export interface TaskHistoryProps {
  item: FindTaskResults
  onClose: () => void
}

const TaskHistory = ({ item, onClose }: TaskHistoryProps) => {
  const [auditHistory, setAuditHistory] = useState<AuditLogDocumentType[]>([])
  const [showingDetailed, setShowingDetailed] = useState<boolean>(false)
  const [taskData, setTaskData] = useState<Task | undefined>(undefined)

  /** Click the close button on the page */
  const handleClick = async () => {
    onClose()
  }

  /** Load the audit history from the server */
  const loadTaskAuditHistory = async (task_id: string) => {
    const list = await AuditLogCollection.getAuditLogForTask(task_id)

    TaskCollection.findTaskById(task_id).then((task) => {
      if (task) setTaskData(task)
    })

    let lastTime = new LogicEngineDataDateTime(new Date(2020, 1, 1))
    const myList: AuditLogDocumentType[] = []
    for (const item of list) {
      const diff = new Date(item.create_dt.toString()).getTime() - lastTime.value.getTime()
      if (diff > 1500) {
        lastTime = new LogicEngineDataDateTime(item.create_dt)
        myList.push(item)
      }
    }

    setAuditHistory(myList)
  }

  const getAuditRow = (audit: AuditLogDocumentType) => {
    // audit.event_text = audit.event_text.replace('/_task' + task_id + '/', '')
    const when = new LogicEngineDataDateTime(audit.create_dt).toStringFormatted('mm/dd HH:MM')
    return (
      <React.Fragment key={audit.id}>
        <br></br>
        <table className='taskHistoryInfo'>
          <tbody>
            <tr>
              <td> Event: </td>
              <td> {audit.event_text} </td>
            </tr>
            <tr>
              <td> User: </td>
              <td> {audit.create_by} </td>
            </tr>
            <tr>
              <td> Timestamp: </td>
              <td> {when} </td>
            </tr>
          </tbody>
        </table>
      </React.Fragment>
    )
  }

  useEffect(() => {
    console.log('TaskHistory item', item)
    loadTaskAuditHistory(item.task_id)
  }, [item])

  const getSimpleHistory = () => {
    return (
      <>
        <br />
        <a
          href='#'
          onClick={() => {
            setShowingDetailed(true)
          }}
        >
          Show Detailed History
        </a>
        <br />
        <br />
        <table className='taskHistoryInfoSimple'>
          <tbody>
            {!taskData || !taskData.data.audit ? (
              <div> Loading Task </div>
            ) : (
              taskData.data.audit.map((audit) => {
                return (
                  <tr key={audit.aid}>
                    <td>{audit.audit_dt.toStringFormatted()}</td>
                    <td> {audit.message}</td>
                  </tr>
                )
              })
            )}
          </tbody>
        </table>
      </>
    )
  }

  const getDetailedHistory = () => {
    return (
      <>
        <br />
        <a
          href='#'
          onClick={() => {
            setShowingDetailed(false)
          }}
        >
          Show Simple History
        </a>
        <br />
        <br />
        {auditHistory.map((audit) => {
          return getAuditRow(audit)
        })}
      </>
    )
  }

  return (
    <div>
      <Modal centered isOpen={item !== undefined} size='lg' className='sync-modal'>
        <div className='modal-header'>
          <h5 className='modal-title' id='exampleModalLabel'>
            Task <b>{item.task_id}</b>
          </h5>
          <button type='button' className='close' data-dismiss='modal' aria-label='Close' onClick={handleClick}>
            <span aria-hidden='true'>&times;</span>
          </button>
        </div>

        <div className='modal-body'>
          <table className='taskHistoryInfo'>
            <tbody>
              <tr>
                <td> Task Title: </td>
                <td> {item.task_title} </td>
              </tr>
              <tr>
                <td> Task Status: </td>
                <td> {item.task_status} </td>
              </tr>
              <tr>
                <td> Workflow State: </td>
                <td>
                  {item.workflow_id} (item.workflow_code) - {item.workflow_description}{' '}
                </td>
              </tr>
              <tr>
                <td> Permissions: </td>
                <td>
                  {item.can_action ? 'Action ' : ''}
                  {item.can_manage ? 'Manage ' : ''}
                  {item.can_see ? 'View ' : ''}
                </td>
              </tr>

              <tr>
                <td> Current Time: </td>
                <td>{new LogicEngineDataDateTime(new Date()).toStringFormatted()}</td>
              </tr>
            </tbody>
          </table>
          {showingDetailed ? getDetailedHistory() : getSimpleHistory()}
        </div>
      </Modal>
    </div>
  )
}

export default TaskHistory
