import { WindowSettings } from '../types/windowSettings'
export class LocalSettings {
  static readonly DWPORTAL_AUTH_TOKEN = 'dwportal_auth_token'
  static readonly DWPORTAL_USER = 'dwportal_user'
  static readonly DWTHEME = 'dwtheme'
  static readonly DWPORTAL_REDIRECT_URL = 'dwportal_redirect_url'
  static readonly DWPORTAL_ENABLE_TRACE = 'dwportal_enable_trace'
  static readonly BUILDING_SEARCH_CONFIG = 'searchConfig'

  static getItem(key: string) {
    return localStorage.getItem(key)
  }

  static setItem(key: string, value: string) {
    localStorage.setItem(key, value)
  }

  static removeItem(key: string) {
    localStorage.removeItem(key)
  }

  static setEnableTrace(value: string) {
    this.setItem(this.DWPORTAL_ENABLE_TRACE, value)
  }

  static removeEnableTrace() {
    this.removeItem(this.DWPORTAL_ENABLE_TRACE)
  }

  static getEnableTrace() {
    return this.getItem(this.DWPORTAL_ENABLE_TRACE)
  }

  static removePortalUser() {
    this.removeItem(this.DWPORTAL_USER)
  }

  static setPortalUser(value: string) {
    this.setItem(this.DWPORTAL_USER, value)
  }

  static getPortalUser() {
    return this.getItem(this.DWPORTAL_USER)
  }

  static removePortalAuthToken() {
    this.removeItem(this.DWPORTAL_AUTH_TOKEN)
  }

  static getPortalAuthToken() {
    return this.getItem(this.DWPORTAL_AUTH_TOKEN)
  }

  static setPortalAuthToken(value: string) {
    return this.setItem(this.DWPORTAL_AUTH_TOKEN, value)
  }

  static setPortalRedirectUrl(value: string) {
    this.setItem(this.DWPORTAL_REDIRECT_URL, value)
  }

  static getPortalRedirectUrl() {
    return this.getItem(this.DWPORTAL_REDIRECT_URL)
  }

  static removePortalRedirectUrl() {
    this.removeItem(this.DWPORTAL_REDIRECT_URL)
  }

  static getTheme() {
    return this.getItem(this.DWTHEME)
  }

  static setTheme(value: string) {
    this.setItem(this.DWTHEME, value)
  }

  static getBuildingSearchConfig() {
    return this.getItem(this.BUILDING_SEARCH_CONFIG)
  }

  static setBuildingSearchConfig(value: string) {
    this.setItem(this.BUILDING_SEARCH_CONFIG, value)
  }

  static removeBuildingSearchConfig() {
    this.removeItem(this.BUILDING_SEARCH_CONFIG)
  }

  static get testAccountPassword() {
    return this.getItem('testAccountPassword') || ''
  }

  static set testAccountPassword(newPass: string) {
    this.setItem('testAccountPassword', newPass)
  }

  static get testAccountCategory() {
    return this.getItem('testAccountCategory') || ''
  }

  static set testAccountCategory(newCategory: string) {
    this.setItem('testAccountCategory', newCategory)
  }

  static set taskDisplayMode(value: string) {
    this.setItem('taskDisplayMode', value)
  }

  static get taskDisplayMode() {
    return this.getItem('taskDisplayMode') || 'list'
  }

  static set traceWindowSettings(settings: WindowSettings) {
    this.setItem('traceWindowSettings', JSON.stringify(settings))
  }

  static get traceWindowSettings() {
    const settingsStr = this.getItem('traceWindowSettings')
    if (settingsStr) {
      const settingsObj = JSON.parse(settingsStr)
      if (settingsObj.screenWidth === window.screen.width && settingsObj.screenHeight === window.screen.height) {
        return settingsObj
      }
    }
    return {
      width: 800,
      height: 600,
      x: 0,
      y: 0,
      screenWidth: window.screen.width,
      screenHeight: window.screen.height,
      innerScreenHeight: window.innerHeight,
      innerScreenWidth: window.innerWidth
    }
  }

  static get forceDbRefresh() {
    const user = JSON.parse(this.getPortalUser() ?? '')
    if (user && user.force_db_refresh) {
      console.log('INIT ForceDBR Refresh is set to true', user)
      return true
    }
    return false
  }
}
