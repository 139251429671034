import React, { useEffect, useState } from 'react'
import { Row, Col, TabPane, Spinner } from 'reactstrap'
import { AuthService } from '@digitalworkflow/dwloginclient'
import {
  PageManager,
  PageEngine,
  StepTabs,
  MetaRefreshType,
  BadgeChangeEvent
} from '@digitalworkflow/dwtranslateclient'
import { projectName } from '../../../constants'
import { RenderHelper } from '../../RenderHelper'
import style from './index.module.scss'
import { GlobalHashHelper } from '../../../utils/HashHelper'

/**
 * Props for [[`PageTab`]] component
 */
export interface PageTabProps {
  /** Tabs info */
  tab: StepTabs.TabInfo

  /** Unique tab number */
  tabIndex: number

  /** Event callback when the number of badge items changes */
  onBadgeChange?: (badgeType: string, badgeCount: number) => void
}

/**
 * PageTab component
 *
 * @component PageTab
 * @category PageElements
 */
const PageTab = (props: PageTabProps) => {
  const [allComponents, setAllComponents] = useState<Array<JSX.Element>>([])
  const [loading, setLoading] = useState(false)

  const pageLoadRender = async (page: PageEngine) => {
    // Create a render engine helper
    const rend_helper = new RenderHelper()

    // Turn on the spinner during loading
    setLoading(true)

    // Notification if there are unanswered questions
    rend_helper.onBadgeChange.subscribe((e: BadgeChangeEvent) => {
      // console.log('Tab Badge Change:', page.routePath, e.badgeType, e.badgeCount)
      props.onBadgeChange && props.onBadgeChange(e.badgeType, e.badgeCount)
    })

    // Find the route and execute it agains the page
    await page.processPage(rend_helper)
    const list: JSX.Element[] = []
    rend_helper.currentTarget.forEach((e) => {
      list.push(e as JSX.Element)
    })

    setAllComponents(list)
    setLoading(false)
  }

  const pageLoad = async (pageUrl: string) => {
    AuthService.setProjectName(projectName)

    // console.log('Tab Page Loading:', projectName, '=', pageUrl)
    const page: PageEngine = await PageManager.instance().findRoute(pageUrl)

    if (page && page.isValid()) {
      await pageLoadRender(page)

      page.evForceRefresh.subscribe((evData: MetaRefreshType) => {
        console.log('Should reload the page....', evData)
        setAllComponents([])
        pageLoadRender(page)
      })
    } else {
      console.log('Tab Page Load not valid:', page)
    }
  }

  useEffect(() => {
    setTimeout(() => {
      pageLoad(props.tab.option1)
    }, 200 * props.tabIndex)
  }, [])

  return (
    <>
      <TabPane key={props.tabIndex} tabId={props.tabIndex}>
        <Row>
          <Col sm='12'>
            <div className='mb-0'>
              {GlobalHashHelper.hasOption('debugtabs') && <p className={style.paragraph}> {props.tab.option1}</p>}
              {loading ? <Spinner color='primary' /> : allComponents}
            </div>
          </Col>
        </Row>
      </TabPane>
    </>
  )
}

export default PageTab
