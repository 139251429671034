import React, { useState } from 'react'
import { Row, Col, Form, FormGroup, Input, Button, Tooltip } from 'reactstrap'

type IConfiguration = {
  showFilterByPostalCode: boolean
  showFilterByFMZ: boolean
  showFilterByRegion: boolean
  showFav: boolean
  minSelect: number
  maxSelect: number
  showNearest: boolean
  showDistance: boolean
  allowPaste: boolean
  clientIds: string
  templateLine1: string
  templateLine2: string
  customFilter?: string
  onChange: (type: string, val: any | null) => void
  onApply: () => void
  onCancel: () => void
}

const Info = (props: { id: string; content: string }) => {
  const { id, content } = props
  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false)
  const toggle = () => setTooltipOpen(!tooltipOpen)
  return (
    <>
      <div className='d-inline-block'>
        <a id={`tooltip-${id}`}>
          <i className='fa-thin fa-circle-info'></i>
        </a>
        <Tooltip autohide flip isOpen={tooltipOpen} target={`tooltip-${id}`} toggle={toggle}>
          {content}
        </Tooltip>
      </div>
    </>
  )
}
const Configurations = (props: IConfiguration) => {
  const {
    showFilterByPostalCode,
    showFilterByFMZ,
    showFilterByRegion,
    showFav,
    minSelect,
    maxSelect,
    showNearest,
    showDistance,
    allowPaste,
    clientIds,
    templateLine1,
    templateLine2,
    customFilter,
    onChange,
    onApply,
    onCancel
  } = props

  return (
    <Form className='configurations'>
      <Row className='mt-1 header'>
        <Col>Configuration Name</Col>
        <Col>Action </Col>
      </Row>
      <Row className='mt-1'>
        <Col>
          Show filter by postcode(s){' '}
          <Info
            id='1'
            content='When true, show an auto complete search that will filter the list of buildings by postal code. See note on postal codes.'
          />
        </Col>
        <Col>
          <FormGroup switch>
            <Input
              type='switch'
              role='switch'
              checked={showFilterByPostalCode}
              onChange={() => {
                onChange('showFilterByPostalCode', null)
              }}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className='mt-1'>
        <Col>
          Show filter by FMZ(s){' '}
          <Info
            id='12'
            content='When true, show an auto complete search that will filter the list of buildings by FMZ. See note on FMZ.'
          />
        </Col>
        <Col>
          <FormGroup switch>
            <Input
              type='switch'
              role='switch'
              checked={showFilterByFMZ}
              onChange={() => {
                onChange('showFilterByFMZ', null)
              }}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className='mt-1'>
        <Col>
          Show filter by Region(s){' '}
          <Info
            id='13'
            content='When true, show an auto complete search that will filter the list of buildings by Region. See note on Regions.'
          />
        </Col>
        <Col>
          <FormGroup switch>
            <Input
              type='switch'
              role='switch'
              checked={showFilterByRegion}
              onChange={() => {
                onChange('showFilterByRegion', null)
              }}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className='mt-1'>
        <Col>
          Show Fav{' '}
          <Info
            id='2'
            content='When true, will add a section titled "Favourite Buildings" (using UK/Canada Spelling) This will get a list of building IDs stored in User Settings. Future Feature: Do not enable in Version 1.'
          />
        </Col>
        <Col>
          <FormGroup switch>
            <Input
              type='switch'
              role='switch'
              checked={showFav}
              onChange={() => {
                onChange('showFav', null)
              }}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className='mt-1'>
        <Col>
          Show Nearest{' '}
          <Info
            id='3'
            content='If true, sort the list by nearest buildings first (default to true). If false, sort the list by building_id.'
          />
        </Col>
        <Col>
          <FormGroup switch>
            <Input
              type='switch'
              role='switch'
              checked={showNearest}
              onChange={() => {
                onChange('showNearest', null)
              }}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className='mt-1'>
        <Col>
          Show Distance{' '}
          <Info
            id='4'
            content='True by default. When true, show a right-aligned value with the distance from the current user to the building location in local units. If the browser does not return a location, disable.'
          />
        </Col>
        <Col>
          <FormGroup switch>
            <Input
              type='switch'
              role='switch'
              checked={showDistance}
              onChange={() => {
                onChange('showDistance', null)
              }}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className='mt-1'>
        <Col>
          Allow Paste{' '}
          <Info
            id='5'
            content='True by default. When set, will attempt to cleanup any text pasted into the field by searching it using regex for building_id or client_building_id values and then selecting those.'
          />
        </Col>
        <Col>
          <FormGroup switch>
            <Input
              type='switch'
              role='switch'
              checked={allowPaste}
              onChange={() => {
                onChange('allowPaste', null)
              }}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className='mt-1'>
        <Col>
          Min Select{' '}
          <Info
            id='6'
            content='The leaste number of buildings that must be selected for the control to be valid. No need to validate at this time.'
          />
        </Col>
        <Col>
          <Input
            type='number'
            value={minSelect}
            onChange={(e) => {
              onChange('minSelect', e.target.value)
            }}
          />
        </Col>
      </Row>
      <Row className='mt-1'>
        <Col>
          Max Select <Info id='7' content='Optional, the maximum number of buildings that can be selected at once.' />
        </Col>
        <Col>
          <Input
            type='number'
            value={maxSelect}
            onChange={(e) => {
              onChange('maxSelect', e.target.value)
            }}
          />
        </Col>
      </Row>
      <Row className='mt-1'>
        <Col>
          Client Ids <Info id='8' content='A list of client_id values to filter from the building list.' />
        </Col>
        <Col>
          <Input
            value={clientIds}
            onChange={(e) => {
              onChange('clientIds', e.target.value)
            }}
          />
        </Col>
      </Row>
      <Row className='mt-1'>
        <Col>
          Template Line 1{' '}
          <Info id='9' content='A simple text template with field replacement to show the building selection item.' />
        </Col>
        <Col>
          <Input
            value={templateLine1}
            onChange={(e) => {
              onChange('templateLine1', e.target.value)
            }}
          />
        </Col>
      </Row>
      <Row className='mt-1'>
        <Col>
          Template Line 2{' '}
          <Info
            id='10'
            content='An optional second line for the building selection template. Shown slightly smaller size, slightly less dark color.'
          />
        </Col>
        <Col>
          <Input
            value={templateLine2}
            onChange={(e) => {
              onChange('templateLine2', e.target.value)
            }}
          />
        </Col>
      </Row>
      <Row className='mt-1'>
        <Col>
          Custom Filter
          <Info id='11' content='Will apply custom filter when calling building search api.' />
        </Col>
        <Col>
          <Input
            value={customFilter}
            onChange={(e) => {
              onChange('customFilter', e.target.value)
            }}
          />
        </Col>
      </Row>
      <Row className='mt-4'>
        <Col className='text-end'>
          <Button color='primary' className='mx-1 px-4' onClick={onApply}>
            Apply
          </Button>
          <Button color='secondary' className='px-4' onClick={onCancel}>
            Cancel
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

export default Configurations
