import React from 'react'
import { useNavigate } from 'react-router-dom'

import { FindTaskResults, TimeAgo } from '@digitalworkflow/dwtranslateclient'

const HtmlToReactParser = require('html-to-react').Parser
const htmlToReactParser = new HtmlToReactParser()

export interface TaskListButtonProps {
  item: FindTaskResults
  setContextTask: (item: FindTaskResults) => void
  setContextMenuPosition: (a: { x: number; y: number }) => void
}

const TaskListButton = ({ item, setContextMenuPosition, setContextTask }: TaskListButtonProps) => {
  const navigate = useNavigate()

  /**
   * Button click handler.
   *
   * @remarks
   * Used for redirecting to targetAction.
   */
  const handleClick = async () => {
    navigate(item.route)
  }

  // static Open: number = 1;
  // static Answered: number = 2;
  // static Resolved: number = 3;
  // static Deleted: number = 4;
  // static NotResolved: number = 5;

  return (
    <div
      className='module-button'
      onClick={handleClick}
      onContextMenu={(e) => {
        e.preventDefault() // prevent the default behaviour when right clicked
        setContextTask(item)
        setContextMenuPosition({
          x: e.pageX,
          y: e.pageY
        })
      }}
    >
      <div
        className='module-title'
        data-tooltip-id='task-list-tooltip'
        data-tooltip-content={htmlToReactParser.parse(item.task_title)}
      >
        {htmlToReactParser.parse(item.task_title)}
      </div>
      <div className='task_detail'>
        <div className='desc'>{item.workflow_description}</div>
        <div className='row'>
          <div className='cell'>
            Created: {TimeAgo.timeAgo(item.create_dt)}, Updated:
            {TimeAgo.timeAgo(item.updated_dt)}
          </div>
          {item.open_query_count > 0 && (
            <div className='cell noteWrapper'>
              <span className='note'>Unanswered Questions: {item.open_query_count}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default TaskListButton
