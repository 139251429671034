import { getRxStorageIndexedDB } from 'rxdb-premium/plugins/storage-indexeddb'

import { LocalDatabaseManager } from '@digitalworkflow/dwtranslateclient'

import { AuthService } from '@digitalworkflow/dwloginclient'
import { projectName } from '../constants'
import { LocalSettings } from './LocalSettings'

export type TableRowCount = {
  [index: string]: number
}

/**
Returns once the database is no longer busy.
Internally this will check the busy state 10 times per second on a timer.
*/
async function checkForDatabaseBusy() {
  const myTimer = setInterval(() => {
    console.log('checking for database busy still...')
  }, 5000)

  console.log('Checking to see if database is busy...')
  await LocalDatabaseManager.instance().isBusy()
  clearInterval(myTimer)

  return true
}

export const clearDatabase = async () => {
  try {
    const instance = LocalDatabaseManager.instance()
    await instance.clearDatabase()
  } catch (error) {
    console.log(error)
  }
}

export const deleteDatabase = async () => {
  try {
    const req = await indexedDB.databases()

    req.forEach(async (item) => {
      await indexedDB.deleteDatabase(item.name as string)
    })
  } catch (error) {
    console.log(error)
  }
}

export const syncDatabase = async (setWidth: (value: number) => void, setSyncing: (value: boolean) => void) => {
  AuthService.setProjectName(projectName)
  const instance = LocalDatabaseManager.instance()
  if (instance.db && !instance.db.destroyed) {
    setSyncing(false)
    return
  }

  const token = LocalSettings.getPortalAuthToken() ?? ''
  await LocalDatabaseManager.startDb(getRxStorageIndexedDB(), token)

  const totalCollections = instance.getCollectionsWithReplication()
  let collections: string[] = totalCollections.map((collection) => collection.getCollectionName())
  const syncEvents = instance.evDatabaseSyncProgress()

  syncEvents.colletionSyncComplete$.subscribe((collection: string) => {
    if (collection) {
      collections = collections.filter((c) => c !== collection)
      const width = parseFloat((100 - (collections.length / totalCollections.length) * 100).toFixed(2))
      setWidth(width)
    }
  })

  // Subscribe to know when the initial pull replication is complete
  syncEvents.initialPullComplete$.subscribe(async (isComplete: boolean) => {
    if (isComplete) {
      console.log('Initial pull replication is complete.')
      await checkForDatabaseBusy()
      await instance.isBusy()
      setWidth(100)
      setSyncing(false)
    }
  })

  // Handle any errors that occur during replication
  syncEvents.error$.subscribe((error: any) => {
    if (error !== null && error !== undefined) {
      console.error('Replication error:', error)
    }
  })

  await instance.isBusy()
}

export const getCollectionsCount = async (): Promise<TableRowCount> => {
  try {
    const instance = LocalDatabaseManager.instance()
    const totalCollections = instance.getCollectionsWithReplication()
    const collections: TableRowCount = {}
    for (let i = 0; i < totalCollections.length; i++) {
      const collection = totalCollections[i]
      const count = await (await (await collection.getRxCollection()).find().exec()).length
      collections[collection.getCollectionName()] = count
    }
    return collections
  } catch (error) {
    console.log(error)
    return {}
  }
}
