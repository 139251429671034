import React, { useEffect, useState, useRef } from 'react'
// import { useNavigate } from 'react-router-dom'
import { FindTaskResults, StepTaskList, LogicEngine } from '@digitalworkflow/dwtranslateclient'
import { RenderHelper } from '../../RenderHelper'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './TaskList.scss'
import TaskListButton from './TaskListButton'
import TaskListTable from './TaskListTable'
import { LocalSettings } from '../../../utils/LocalSettings'
import { getTaskListSettings } from './TaskListSettings'
import { GlobalHashHelper } from '../../../utils/HashHelper'
import TaskListDebug from './TaskListDebug'
import TaskHistory from './TaskHistory'
import TaskReassign from './TaskReassign'
import { TaskListButtonContextMenu } from './TaskListButtonContextMenu'
import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'

export interface TaskListProps {
  /** Step data */
  step: StepTaskList

  /** Used for redirecting. */
  renderer: RenderHelper

  logic: LogicEngine | undefined
}

/**

Display a list of tasks for the user to complete

*/

type displayModeType = 'list' | 'table'

const TaskList = ({ step, logic }: TaskListProps) => {
  const [taskList, setTaskList] = useState<FindTaskResults[]>([])
  const [displayMode, setDisplayMode] = useState<displayModeType>(LocalSettings.taskDisplayMode as displayModeType)
  const [showHistoryForTask, setShowHistoryForTask] = useState<FindTaskResults | undefined>(undefined)
  const [reassignTask, setReassignTask] = useState<FindTaskResults | undefined>(undefined)
  const [contextTask, setContextTask] = useState<FindTaskResults | undefined>(undefined)

  const [menuShowing, setMenuShowing] = React.useState<boolean>(false)
  const [menuPosition, setMenuPosition] = React.useState<{ x: number; y: number }>({ x: 0, y: 0 })

  const [contextMenuPosition, setContextMenuPosition] = useState<{ x: number; y: number }>({ x: 0, y: 0 })

  useEffect(() => {
    const handleClick = () => setContextTask(undefined)

    window.addEventListener('click', handleClick)
    return () => {
      window.removeEventListener('click', handleClick)
    }
  }, [])

  /** Show tasks that are filtered out because of rejected status */
  const [optionShowRejected, setOptionShowRejected] = useState<boolean>(false)

  /** We need to use a reference to the task list instead of the state only because
  the state update messages are async and the subscribe event can happen too fast
  for a redraw to complete. */
  const myTaskList = useRef<FindTaskResults[]>([])

  /** Display the popup menu upon clicking the share button */
  const handleMoreOptionsMenu = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault()
    setMenuShowing(!menuShowing)
    const maxX = window.innerWidth - 400 // Adjust the value as needed
    const maxY = window.innerHeight - 400 // Adjust the value as needed
    const x = Math.min(e.clientX, maxX)
    const y = Math.min(e.clientY, maxY)
    setMenuPosition({ x, y })
  }

  useEffect(() => {
    // Set event to update the task list
    step.subscribe((list) => {
      console.log('TaskList step.subscribe Set Task list:', list)

      const finalList = list.filter((item: FindTaskResults) => {
        if (item && item.task_status === 'Created') {
          let diff = new Date().getTime() - new Date(item.create_dt.value).getTime()
          diff /= 86400000
          if (diff > 2) return false
        }
        return true
      })

      myTaskList.current = [...finalList]
      setTaskList(myTaskList.current)

      // setTimeout(() => {
      //   onShowHistory('lusnpo10zbbf')
      // }, 1500)
    })

    const savedOptionShowCancelled = LocalSettings.getItem('optionShowRejected')
    if (savedOptionShowCancelled && savedOptionShowCancelled === 'true') {
      setOptionShowRejected(true)
    }
  }, [])

  const generateButtonList = (categoryName: string) => {
    let tasks = taskList
      .filter((task) => task.dash_category === categoryName)
      .filter((task) => task.create_dt.value !== undefined)
      .filter((task) => task.create_dt.value.getTime !== undefined)

    tasks = tasks.sort((a, b) => b.create_dt.value.getTime() - a.create_dt.value.getTime())

    return (
      <div key={'tl' + categoryName} className={getTaskListSettings(categoryName).style}>
        <div className='task_category'>
          {categoryName}
          <span className='badge badge-pill'>{tasks.length}</span>
        </div>
        <div className='task_button_list'>
          {tasks.map((task, index) => {
            return (
              <TaskListButton
                key={'task' + index}
                item={task}
                setContextTask={setContextTask}
                setContextMenuPosition={setContextMenuPosition}
              />
            )
          })}
        </div>
      </div>
    )
  }

  /** Does the current user have admin / manage access on the page */
  const hasManageAccess = () => {
    return false
  }

  /** Called when the manage button is pressed.  The user has manage access to
  the project and will show a special interface for the management functions */
  const handleManageButton = () => {
    setTaskList([])
    console.log('Calling search')
    step.processSearch('Admin,Complete,Drafs,OnHold,Cancelled')
  }

  const handleRefreshButton = () => {
    setTaskList([])
    step.processSearch()
  }

  let categories = taskList.map((task) => task.dash_category)
  categories = categories.filter((value, index, self) => {
    return self.indexOf(value) === index
  })

  categories = categories.sort((a, b) => {
    return getTaskListSettings(a).order - getTaskListSettings(b).order
  })

  /** Show a history window for the selected task */
  const onShowHistory = (task_id: string): void => {
    console.log('Showing history for task', task_id)
    for (const item of taskList) {
      if (item.task_id === task_id) {
        setShowHistoryForTask(item)
        return
      }
    }
  }

  /** Show a reassign window for the selected task */
  const onShowReassignModal = (task_id: string): void => {
    console.log('Showing reassign task', task_id)
    for (const item of taskList) {
      if (item.task_id === task_id) {
        setReassignTask(item)
        return
      }
    }
  }

  return (
    <div key='task_list_wrapper'>
      {GlobalHashHelper.hasOption('debug') && <TaskListDebug items={taskList} />}
      <div className='task_list_wrapper'>
        <div className='title'>{step.getTitle()} </div>

        {hasManageAccess() && (
          <>
            <div
              className='icon-manage'
              data-testid='icon-manage'
              onClick={() => {
                handleManageButton()
              }}
            >
              Manage
            </div>
            <div className='iconBreak'></div>
          </>
        )}

        <div
          className='icon'
          data-testid='reload'
          onClick={() => {
            handleRefreshButton()
          }}
        >
          <FontAwesomeIcon icon={['fas', 'arrows-rotate']} />
        </div>

        <div className='iconBreak'></div>

        <div
          className='icon'
          data-testid='icon-list'
          onClick={() => {
            setDisplayMode('list')
            LocalSettings.taskDisplayMode = 'list'
          }}
        >
          <FontAwesomeIcon icon={['fas', 'braille']} color={displayMode === 'list' ? '#00a6b6' : '#253746'} />
        </div>

        <div
          className='icon'
          data-testid='icon-table'
          onClick={() => {
            setDisplayMode('table')
            LocalSettings.taskDisplayMode = 'table'
          }}
        >
          <FontAwesomeIcon icon={['fas', 'table']} color={displayMode === 'table' ? '#00a6b6' : '#253746'} />
        </div>

        <div className='iconBreak'></div>

        <div
          className='icon'
          data-testid='icon-table'
          onClick={(e) => {
            handleMoreOptionsMenu(e)
          }}
        >
          <FontAwesomeIcon icon={['fas', 'caret-down']} color='#253746' />
        </div>
      </div>
      {displayMode === 'list' &&
        categories.map((category) => {
          return generateButtonList(category || '')
        })}
      {displayMode === 'table' && (
        <TaskListTable
          options={step.options}
          items={taskList}
          onShowHistory={onShowHistory}
          onShowReassignModal={onShowReassignModal}
          optionShowRejected={optionShowRejected}
        />
      )}
      {contextTask && (
        <TaskListButtonContextMenu top={contextMenuPosition.y} left={contextMenuPosition.x}>
          <ul>
            <li onClick={() => onShowHistory(contextTask.task_id)}>History of task</li>
            <li onClick={() => onShowReassignModal(contextTask.task_id)}>Reassign task</li>
          </ul>
        </TaskListButtonContextMenu>
      )}
      {showHistoryForTask && <TaskHistory item={showHistoryForTask} onClose={() => setShowHistoryForTask(undefined)} />}
      {reassignTask && <TaskReassign item={reassignTask} logic={logic} onClose={() => setReassignTask(undefined)} />}

      <div
        className={'dropdown-background ' + (menuShowing ? 'dropdown-showing' : 'dropdown-hidden')}
        onClick={() => setMenuShowing(false)}
      ></div>

      <div
        className={'dropdown-menu dropdown-menu-sm ' + (menuShowing ? 'dropdown-showing' : 'dropdown-hidden')}
        style={{ top: menuPosition.y, left: menuPosition.x }}
        id='context-menu'
      >
        <a
          className='dropdown-item'
          onClick={() => {
            setOptionShowRejected(!optionShowRejected)
            LocalSettings.setItem('optionShowRejected', optionShowRejected ? 'false' : 'true')
            setMenuShowing(false)
            handleRefreshButton()
          }}
        >
          <FontAwesomeIcon icon={optionShowRejected ? ['fal', 'circle-check'] : ['fal', 'circle']} />
          <span style={{ marginLeft: '10px' }}>Show Rejected and Cancelled</span>
        </a>
      </div>
      <Tooltip id='task-list-tooltip' />
    </div>
  )
}

export default TaskList
