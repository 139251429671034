import React, { useEffect, useState } from 'react'

import {
  LogicEngine,
  FindTaskResults,
  TaskCollection,
  WorkflowStateCollection,
  WorkflowState,
  NoteCollection
} from '@digitalworkflow/dwtranslateclient'
import { Modal, Input, Button } from 'reactstrap'
export interface TaskReassignProps {
  item: FindTaskResults
  logic: LogicEngine | undefined
  onClose: () => void
}

interface TaskStatusOption {
  label: string
  value: number
}

const taskStatusOptions: TaskStatusOption[] = [
  {
    label: 'TaskCreated',
    value: 100
  },
  {
    label: 'TaskInProgress',
    value: 1
  },
  {
    label: 'TaskReviewed',
    value: 5000
  },
  {
    label: 'TaskOnHold',
    value: 7000
  },
  {
    label: 'TaskRejected',
    value: 910
  },
  {
    label: 'TaskClosed',
    value: 900
  },
  {
    label: 'TaskExpired',
    value: 4500
  },
  {
    label: 'TaskCancelled',
    value: 6000
  },
  {
    label: 'TaskError',
    value: 4000
  },
  {
    label: 'TaskInvalid',
    value: 9999
  }
]

const TaskReassign = ({ item, logic, onClose }: TaskReassignProps) => {
  const [taskStatus, setTaskStatus] = useState<number | undefined>(item.task_status_id)
  const [workflowState, setWorkflowState] = useState<string | undefined>(item.workflow_code)
  const [assignedUser, setAssignedUser] = useState<string>(item.auser)
  const [workflowStateOptions, setWorkflowStateOptionss] = useState<WorkflowState[]>([])

  useEffect(() => {
    async function getDropdownOptions() {
      const items: WorkflowState[] = await WorkflowStateCollection.getWorkflowStatesForProject('psif')
      console.log(items)
      setWorkflowStateOptionss(items)
    }

    getDropdownOptions()
  }, [])
  /** Click the close button on the page */
  const handleClick = async () => {
    onClose()
  }

  const handleSelectStatus = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setTaskStatus(parseInt(e.target.value))
  }

  const handleSelectWorkflowState = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setWorkflowState(e.target.value)
  }

  const handleChangeAssignedUser = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAssignedUser(e.target.value)
  }

  /** Click the update button on the page */
  const handleUpdate = async () => {
    onClose()
    if (!logic) return false
    const task = await TaskCollection.findTaskById(item.task_id)
    if (!task) return false
    const reason = 'Reassign task'

    if (taskStatus) {
      console.log('taskStatus update', taskStatus)
      await task.setStatus(taskStatus, reason)
      task.importIntoLogicEngine(logic)
      await task.verifyTaskPermissions(logic)

      // Add auto log note about the change via management function
      const taskTitle = taskStatusOptions.find((option) => option.value === taskStatus)?.label
      const note = NoteCollection.createNote()
      note.setNoteText('The task status has been updated to ' + taskTitle)
      note.setReferenceObject('object_type', 'task')
      note.setReferenceObject('related_object', item.task_id)
      note.setProject('psif')

      await note.save()
    }

    if (workflowState) {
      console.log('workflowState update', workflowState)
      await task.setWorkflowState(workflowState, reason, 'psif')
      task.importIntoLogicEngine(logic)
      await task.verifyTaskPermissions(logic)

      // Add auto log note about the change via management function
      const workflowTitle = workflowStateOptions.find((option) => option.data.code === workflowState)?.data.name
      const note = NoteCollection.createNote()
      note.setNoteText('The task workflow has been updated to ' + workflowTitle)
      note.setReferenceObject('object_type', 'task')
      note.setReferenceObject('related_object', item.task_id)
      note.setProject('psif')

      await note.save()
    }

    if (assignedUser) {
      console.log('assignUser update', assignedUser)
      await task.assignUser(assignedUser, reason, true)
      task.importIntoLogicEngine(logic)

      // Add auto log note about the change via management function
      const note = NoteCollection.createNote()
      note.setNoteText('The task has been assigned to ' + assignedUser)
      note.setReferenceObject('object_type', 'task')
      note.setReferenceObject('related_object', item.task_id)
      note.setProject('psif')

      await note.save()
    }

    return true
  }

  return (
    <div>
      <Modal centered isOpen={item !== undefined} size='lg' className='task-reassign-modal'>
        <div className='modal-header'>
          <h5 className='modal-title'>
            Reassign Task <b>{item.task_id}</b>
            <br />
            Title: <b>{item.task_title}</b>
          </h5>
          <button type='button' className='close' data-dismiss='modal' aria-label='Close' onClick={handleClick}>
            <span aria-hidden='true'>&times;</span>
          </button>
        </div>

        <div className='modal-body'>
          <div className='mb-2 row'>
            <label className='col-md-4 col-form-label'>Status</label>
            <div className='col-md-8'>
              <select className='form-select drop-down' name='status' onChange={handleSelectStatus} value={taskStatus}>
                <option value=''>Select</option>
                {taskStatusOptions?.map((item: TaskStatusOption) => (
                  <option key={item.value} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className='mb-2 row'>
            <label className='col-md-4 col-form-label'>Workflow State</label>
            <div className='col-md-8'>
              <select
                className='form-select drop-down'
                name='workflowState'
                onChange={handleSelectWorkflowState}
                value={workflowState}
              >
                <option value=''>Select</option>
                {workflowStateOptions?.map((item: WorkflowState) => (
                  <option key={item.data.id} value={item.data.code}>
                    {item.data.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className='mb-2 row'>
            <label className='col-md-4 col-form-label'>Assigned User</label>
            <div className='col-md-8'>
              <Input type='text' name='user' value={assignedUser} onChange={handleChangeAssignedUser} />
            </div>
          </div>
          <div className='row'>
            <label className='col-md-4'></label>
            <div className='col-md-8'>
              <Button color='primary' onClick={() => handleUpdate()}>
                Update
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default TaskReassign
